@use "vars"

.solarized-em
	color: #EEE8D5
.solarized-gold
	color: #B58900
.solarized-orange
	color: #CB4B16
.solarized-red
	color: #DC322F
.solarized-pink
	color: #D33682
.solarized-purple
	color: #6C71C4
.solarized-blue
	color: vars.$solarized-blue
.solarized-teal
	color: #2AA198
.solarized-green
	color: #859900

.green
	color: green
.white
	color: white