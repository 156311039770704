.solarized-em {
  color: #EEE8D5;
}

.solarized-gold {
  color: #B58900;
}

.solarized-orange {
  color: #CB4B16;
}

.solarized-red {
  color: #DC322F;
}

.solarized-pink {
  color: #D33682;
}

.solarized-purple {
  color: #6C71C4;
}

.solarized-blue {
  color: #268BD2;
}

.solarized-teal {
  color: #2AA198;
}

.solarized-green {
  color: #859900;
}

.green {
  color: green;
}

.white {
  color: white;
}

.markdown {
  font-family: Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
  color: #c9d1d9;
  font-size: 16px;
  line-height: 1.5;
  max-width: 800px;
  margin: auto;
}

.markdown small {
  color: #9ca2a8;
}

.markdown h1, .markdown h2, .markdown h3 {
  margin-top: 24px;
  margin-bottom: 16px;
  font-weight: 600;
  line-height: 1.25;
}

.markdown h1, .markdown h2 {
  border-bottom: 1px solid #21262d;
  padding-bottom: 0.3em;
}

.markdown h1 {
  font-size: 2em;
}

.markdown h2 {
  font-size: 1.5em;
}

.markdown p {
  margin-top: 0;
  margin-bottom: 16px;
}

.markdown a {
  text-decoration: none;
  color: #58a6ff;
}

.markdown .topics {
  margin-bottom: 10px;
}

.markdown .tag {
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  border: 1px solid transparent;
  border-radius: 2em;
  padding: 0 10px;
}

.markdown .tag.lang {
  color: #71ff58;
  background-color: rgba(86, 253, 56, 0.2);
}

.markdown .tag.plat {
  color: #58a6ff;
  background-color: #388bfd33;
}

.markdown .tag.frame {
  color: #d0a2ff;
  background-color: rgba(175, 83, 255, 0.2);
}

.project.content {
  padding: 15px;
  user-select: text;
}

.project .site {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
}

.project .icon {
  width: 40px;
  display: inline-block;
  margin-bottom: -8px;
  margin-right: 8px;
}

.project .banner {
  margin: auto;
  max-width: 100%;
  width: 300px;
  display: block;
}

.project img {
  width: 100%;
}

.project .video {
  position: relative;
  padding-top: 56.25%;
}

.project .video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

@font-face {
  font-family: "Cascadia Code";
  src: url("/woff/CascadiaCode.woff2");
}
html, body {
  margin: 0;
  height: 100%;
  width: 100%;
  background-color: #111;
  font-family: "Cascadia Code", monospace;
}

a:hover {
  text-decoration: underline !important;
}

#app {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
}