@use "vars"

.markdown
	font-family: Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji
	color: vars.$markdown-primary
	font-size: 16px
	line-height: 1.5
	max-width: 800px
	margin: auto

.markdown small
	color: vars.$markdown-secondary

.markdown h1, .markdown h2, .markdown h3
	margin-top: 24px
	margin-bottom: 16px
	font-weight: 600
	line-height: 1.25

.markdown h1, .markdown h2
	border-bottom: 1px solid #21262d
	padding-bottom: .3em

.markdown h1
	font-size: 2em

.markdown h2
	font-size: 1.5em

.markdown p
	margin-top: 0
	margin-bottom: 16px

.markdown a
	text-decoration: none
	color: #58a6ff

.markdown .topics
	margin-bottom: 10px

.markdown .tag
	display: inline-block
	font-size: 12px
	font-weight: 500
	line-height: 18px
	border: 1px solid transparent
	border-radius: 2em
	padding: 0 10px

.markdown .tag.lang
	color: #71ff58
	background-color: rgba(86, 253, 56, 0.2)

.markdown .tag.plat
	color: #58a6ff
	background-color: #388bfd33

.markdown .tag.frame
	color: #d0a2ff
	background-color: rgba(175, 83, 255, 0.2)
