.project.content
	padding: 15px
	user-select: text

.project .site
	position: absolute
	top: 0
	right: 0
	display: block

.project .icon
	width: 40px
	display: inline-block
	margin-bottom: -8px
	margin-right: 8px

.project .banner
	margin: auto
	max-width: 100%
	width: 300px
	display: block

.project img
	width: 100%

.project .video
	position: relative
	padding-top: 56.25%

.project .video iframe
	position: absolute
	top: 0
	left: 0
	width: 100%
	height: 100%
	border: none
